<template>
<div>
  <b-card no-body>
    <div class="m-2">

      <!-- Table Top -->
      <b-row class="pb-1">
        <b-col cols="8">
          <h4>Danh sách {{options.filter(i => i.value===this.selected)[0].text}}</h4>
        </b-col>
        <b-col cols="4" class="text-right">
          <b-button
            variant="primary"
            @click="New()" class="btn-sm">
            <feather-icon
                  icon="PlusIcon"
                  size="16"
                  class="align-text-top"
                /> Thêm {{this.options.filter(i => i.value==this.selected)[0].text}}
          </b-button>
        </b-col>
        <!-- Per Page -->
      </b-row>

      <b-row>
        <b-col cols="12">
          <vue-good-table :columns="fields" :rows="items" :line-numbers="true" :pagination-options="{enabled: true}"
            mode="remote"
            @on-page-change="onPageChange"
            @on-sort-change="onSortChange"
            @on-column-filter="onColumnFilter"
            @on-per-page-change="onPerPageChange"
            :totalRows="totalRecords"
            :isLoading.sync="isLoading"
          >
            <template
              slot="table-row"
              slot-scope="props"
            >

              <!-- Column: Action -->
              <span v-if="props.column.field === 'action'">
                <span>
                
                     <b-button
                        variant="primary" class="btn-icon" size="sm" @click="Edit(props.row,props.row.id)">
                        <feather-icon icon="Edit2Icon"/>
                      </b-button>
                      <b-button
                        variant="danger"
                        class="btn-icon"
                        style="margin-left:6px"
                        size="sm"
                        @click="Delete(props.row,props.row.id)">
                        <feather-icon icon="TrashIcon"/>
                      </b-button>
                </span>
              </span>

              <!-- Column: Common -->
               <span v-else-if="allFields.filter(i=>i.field==props.column.field)[0].select2 && allFields.filter(i=>i.field==props.column.field)[0].select2.filter(i => i.id == props.row[props.column.field] ).length>0">
                {{ allFields.filter(i=>i.field==props.column.field)[0].select2.filter(i => i.id == props.row[props.column.field])[0].text }}      
              </span>
              <span v-else>
                {{ props.formattedRow[props.column.field]}}
              </span>
              
            </template>

            <!-- pagination -->
            <template
              slot="pagination-bottom"
              slot-scope="props"
            >
              <div class="d-flex justify-content-between flex-wrap">
                <div class="d-flex align-items-center mb-0 mt-1">
                  <span class="text-nowrap">
                    Showing 1 to
                  </span>
                  <b-form-select
                    v-model="serverParams.pageSize"
                    :options="['50','100','500']"
                    class="mx-1"
                    @input="(value)=>props.perPageChanged({currentPerPage:value})"
                  />
                  <span class="text-nowrap "> of {{ items.length }} entries </span>
                </div>
                <div>
                  <b-pagination
                    :value="1"
                    :total-rows="totalRecords"
                    :per-page="serverParams.pageSize"
                    first-number
                    last-number
                    align="right"
                    prev-class="prev-item"
                    next-class="next-item"
                    class="mt-1 mb-0"
                    @input="(value)=>props.pageChanged({currentPage:value})"
                  >
                    <template #prev-text>
                      <feather-icon
                        icon="ChevronLeftIcon"
                        size="18"
                      />
                    </template>
                    <template #next-text>
                      <feather-icon
                        icon="ChevronRightIcon"
                        size="18"
                      />
                    </template>
                  </b-pagination>
                </div>
              </div>
            </template>
          </vue-good-table>
        </b-col>

      </b-row>
    </div>   
  </b-card>

  <b-modal size="lg" id="modal-edit" :title="getTitle()" @ok="handleOk">
    <validation-observer ref="modalEdit">
      <b-row>
        <b-col cols="12" :md="i.cols != null?i.cols:6" v-for="i in allFields.filter(i => i.field!=='action')" :key="i.field">
          <validation-provider :name="'\''+i.label+'\''" #default="{ errors }" :rules="( !i.autoIncrement && (i.field=='name' || i.field=='id' || i.required))?'required':''" v-if="i.select2==null">        
            <b-form-group :label="i.label" v-if="i.date_time==true" >
              <b-form-datepicker
                    :state="errors.length > 0 ? false:null"
                      :date-format-options="{ year: 'numeric', month: '2-digit',day: '2-digit', }" locale="vi-VN"
                      v-model="currentItem[i.field]"
                    />
              </b-form-group>
            <b-form-group :label="i.label" v-else>
              <b-form-input  :disabled="(i.field==='id' || i.disabled) && editMode || i.autoIncrement" :state="errors.length > 0 ? false:null" v-model="currentItem[i.field]" :placeholder="i.autoIncrement?'Hệ thống tự sinh':i.label"/>
              <small class="text-danger">{{ errors[0] }}</small>
            </b-form-group>        
          </validation-provider>
          <validation-provider :name="'\''+i.label+'\''" #default="{ errors }" :rules="(i.field=='name' || i.field=='id' || i.required)?'required':''" v-else>
            <b-form-group :label="i.label">
              <Select2 :state="errors.length > 0 ? false:null" :placeholder="i.label" v-model="currentItem[i.field]" :options="i.select2"/>
              <small class="text-danger">{{ errors[0] }}</small>
            </b-form-group>
          </validation-provider>
        </b-col>
      </b-row>
    </validation-observer>
  </b-modal>
</div>
</template>

<script>


import { ValidationProvider, ValidationObserver,extend } from 'vee-validate'
import { required } from '@validations'
import vSelect from 'vue-select'
import { VueGoodTable } from 'vue-good-table'
import { BFormDatepicker, BCard, BRow, BCol, BFormInput, BButton, BTable, BMedia, BAvatar, BLink,
  BBadge, BDropdown, BDropdownItem, BPagination, BTooltip,BModal,BFormCheckbox,BFormGroup,VBTooltip,BFormSelect } from 'bootstrap-vue'
import useJwt from "@/auth/jwt/useJwt";
import FeatherIcon from '@/@core/components/feather-icon/FeatherIcon.vue';
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import Ripple from 'vue-ripple-directive'
import '@core/scss/vue/libs/vue-good-table.scss';
import Select2 from 'v-select2-component';
import moment from 'moment';
import { stringToDate } from "@/@core/utils/utils";


export default {
  
 components: {
    BFormDatepicker,BCard, BRow, BCol, BFormInput, BButton, BTable, BMedia, BAvatar, BLink,
    BBadge, BDropdown, BDropdownItem, BPagination, BTooltip,vSelect,
    FeatherIcon,BModal,BFormGroup,BFormCheckbox,ValidationProvider, ValidationObserver,BFormSelect,VueGoodTable,Select2
  },

  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },
  mounted(){
    this.loadMasterData();
    
  },
  watch: {
    $route(to, from) {
      this.loadMasterData();
    }
  },
  methods: {
    
    loadMasterData(){
      this.selected = this.$route.meta.type;
      this.allFields = this.options.filter(i=>i.value==this.selected)[0].fields;
      this.fields = JSON.parse(JSON.stringify(this.options.filter(i=>i.value==this.selected)[0].fields));
      this.fields.forEach (i=>{
        delete i.select2;delete i.cols;delete i.autoIncrement; delete i.required; 
      })
      this.loadItems();
    },
    getTitle(){
      if (this.editMode){
        return 'Cập nhật '+this.options.filter(i => i.value===this.selected)[0].text
      }else{
        return 'Thêm mới '+this.options.filter(i => i.value===this.selected)[0].text
      }
    },
    updateParams(newProps) {
      this.serverParams = Object.assign({}, this.serverParams, newProps);
    },
    
    onPageChange(params) {
      this.updateParams({page: params.currentPage});
      this.loadItems();
    },
    onPerPageChange(params) {
      this.updateParams({pageSize: params.currentPerPage});
      this.loadItems();
    },
    onSortChange(params) {
      this.updateParams({
        sort: params,
      });
      this.loadItems();
    },
    onColumnFilter(params) {
      this.updateParams(params);
      this.loadItems();
    },
    loadItems() {
      useJwt.post('getMasterData/'+this.selected,this.serverParams).then(response =>{
        if (response.data.extra!=null){
          for (var key of Object.keys(response.data.extra)) {
            this.options.filter(i=>i.value==this.selected)[0].fields.filter(i=>i.field === key)[0].select2 = response.data.extra[key];
          }
        }
        this.items = response.data.result;
        this.totalRecords = response.data.totalRecords;
        this.isLoading = false;
      })
    },
    Edit(item, index, button) {
      this.currentItem = item;
      this.editMode=true;
      /*
      this.options.forEach(i=>{
        i.fields.forEach(j=>{
            if(j.date_time){
                //this.currentItem[j.field] = new Date(this.currentItem[j.field]);
            }
        });
      });
      */
      this.modal_title= "Cập nhật "+this.options.filter(i => i.value===this.selected)[0].text
      this.$bvModal.show('modal-edit')
    },
    New(){
      this.currentItem={}
      this.editMode=false;
      this.modal_title= "Thêm mới "+this.options.filter(i => i.value===this.selected)[0].text
      this.$bvModal.show('modal-edit')
    },
    async handleOk(bvModalEvt){
      bvModalEvt.preventDefault()
      var validate = await this.$refs.modalEdit.validate().then(success => {return success;});
      if (!validate){
        this.$toast({component: ToastificationContent,props: {title: 'Vui lòng kiểm tra dữ liệu!',icon: 'AlertTriangleIcon',variant: 'warning'}})
        return;
      }
      console.log(this.currentItem);
      if (!this.editMode){
        useJwt.post('masterData/'+this.selected, this.currentItem)
        .then(response =>{
          this.loadItems();
          this.$bvModal.hide('modal-edit')
          this.$toast({component: ToastificationContent,props: {title: 'Thêm mới '+this.options.filter(i => i.value===this.selected)[0].text+' thành công!',icon: 'CheckSquareIcon',variant: 'primary'}})
        })
        .catch(error => {
          this.$toast({component: ToastificationContent,props: {title: 'Lỗi thêm mới '+this.options.filter(i => i.value===this.selected)[0].text,icon: 'AlertTriangleIcon',variant: 'danger'}})
        })
      }else{
        useJwt.put('masterData/'+this.selected,this.currentItem)
        .then(response =>{
          this.loadItems();
          this.$bvModal.hide('modal-edit')
          this.$toast({component: ToastificationContent,props: {title: 'Cập nhật '+this.options.filter(i => i.value===this.selected)[0].text+' thành công!',icon: 'CheckSquareIcon',variant: 'primary'}})
        })
        .catch(error => {
          this.$toast({component: ToastificationContent,props: {title: 'Lỗi cập nhật '+this.options.filter(i => i.value===this.selected)[0].text+ ' ['+error.response.data.result+']!',icon: 'AlertTriangleIcon',variant: 'danger'}})
        })
      }
    },
    async Delete(item, index, button) {
      this.currentItem = item;
      var res = await this.$bvModal.msgBoxConfirm('Vui lòng xác nhận bạn muốn xoá dữ liệu '+this.selected, {title: 'Please Confirm',size: 'sm',})
      if (res){
        useJwt.delete(`masterData/${this.selected}/${this.currentItem.id}`, {})
        .then(response =>{
          this.loadItems();
          this.$toast({component: ToastificationContent,props: {title: 'Xoá '+this.options.filter(i => i.value===this.selected)[0].text+' thành công!',icon: 'CheckSquareIcon',variant: 'primary'}})
        })
        .catch(error => {
          console.log(error);
          this.$toast({component: ToastificationContent,props: {title: 'Lỗi xoá '+this.options.filter(i => i.value===this.selected)[0].text,icon: 'AlertTriangleIcon',variant: 'danger'}})
        })
      }
    },
  },
  data() {
    return {
      isLoading: false,
      totalRecords: 0,
      items: [],
      editMode:false,
      totalItem:0,
      permisItems: [],
      searchQuery:"",
      searchTerm: '',
      currentItem:{},
      selected: 'catalog_types',
      options: [
         {
          value:'catalog_types',
          text:'Loại văn bản',
          fields: [
            {
              field: 'id',
              filterOptions: {enabled: true, placeholder: ''},
              label: 'ID loại văn bản',
              required:true,
              autoIncrement: true,
              width: '80px',
              hidden: true,
            },
            {
              field: 'name',
              filterOptions: {enabled: true, placeholder: ''},
              label: 'Tên loại văn bản',
              required:true,
            },
            { field: 'action', label: 'Actions',width: '90px', }
          ],
        }, {
          value:'catalog_ranges',
          text:'Phạm vi',
          fields: [
            {
              field: 'id',
              filterOptions: {enabled: true, placeholder: ''},
              label: 'ID phạm vi',
              required:true,
              autoIncrement: true,
              width: '80px',
              hidden: true,
            },
            {
              field: 'name',
              filterOptions: {enabled: true, placeholder: ''},
              label: 'Tên phạm vi',
              required:true,
            },
            { field: 'action', label: 'Actions',width: '90px', }
          ],
        },

        {
          value:'catalog_where_issued',
          text:'Nơi ban hành',
          fields: [
            {
              field: 'id',
              filterOptions: {enabled: true, placeholder: ''},
              label: 'ID nơi ban hành',
              required:true,
              autoIncrement: true,
              width: '100px',
              hidden: true,
            },
            {
              field: 'name',
              filterOptions: {enabled: true, placeholder: ''},
              label: 'Nơi ban hành',
              required:true,
            },

            {
              field: 'range_id',
              filterOptions: {enabled: true, placeholder: ''},
              label: 'Phạm vi',
              select2:[],
              required:true,
            }, 
            {
              field: 'wdetail_id',
              filterOptions: {enabled: true, placeholder: ''},
              label: 'Phạm vi chi tiết',
              select2:[],
              required:true,
            },


            { field: 'action', label: 'Actions',width: '90px', }
          ],
        },
        {
          value:'catalog_wdetail_issued',
          text:'Nơi ban hành chi tiết',
          fields: [
            {
              field: 'id',
              filterOptions: {enabled: true, placeholder: ''},
              label: 'ID nơi ban hành chi tiết',
              required:true,
              autoIncrement: true,
              width: '80px',
              hidden: true,
            },
            {
              field: 'name',
              filterOptions: {enabled: true, placeholder: ''},
              label: 'Tên nơi ban hành chi tiết',
              required:true,
            },
            { field: 'action', label: 'Actions',width: '90px', }
          ],
        },
        {
          value:'catalog_fields',
          text:'Lĩnh vực',
          fields: [
            {
              field: 'id',
              filterOptions: {enabled: true, placeholder: ''},
              label: 'ID lĩnh vực',
              required:true,
              autoIncrement: true,
              width: '80px',
              hidden: true,
            },
            {
              field: 'name',
              filterOptions: {enabled: true, placeholder: ''},
              label: 'Tên lĩnh vực',
              required:true,
            },
            { field: 'action', label: 'Actions',width: '90px', }
          ],
        },
        {
          value:'catalog_fdetails',
          text:'Lĩnh vực chi tiết',
          fields: [
            {
              field: 'id',
              filterOptions: {enabled: true, placeholder: ''},
              label: 'ID lĩnh vực chi tiết',
              required:true,
              autoIncrement: true,
              width: '80px',
              hidden: true,
            },
            {
              field: 'name',
              filterOptions: {enabled: true, placeholder: ''},
              label: 'Tên lĩnh vực chi tiết',
              required:true,
            },
            {
              field: 'field_id',
              filterOptions: {enabled: true, placeholder: ''},
              label: 'Lĩnh vực',
              select2:[],
              required:true,
              cols:12
            },
            

            { field: 'action', label: 'Actions',width: '90px', }
          ],
        },
        {
          value:'catalog_positions',
          text:'Chức vụ người ký',
          fields: [
            {
              field: 'id',
              filterOptions: {enabled: true, placeholder: ''},
              label: 'ID chức vụ người ký',
              required:true,
              autoIncrement: true,
              width: '80px',
              hidden: true,
            },
            {
              field: 'name',
              filterOptions: {enabled: true, placeholder: ''},
              label: 'Tên chức vụ người ký',
              required:true,
            },
            { field: 'action', label: 'Actions',width: '90px', }
          ],
        },
       
        {
          value:'catalog_status',
          text:'Tình trạng văn bản',
          fields: [
            {
              field: 'id',
              filterOptions: {enabled: true, placeholder: ''},
              label: 'ID tình trạng',
              required:true,
              autoIncrement: true,
              width: '80px',
              hidden: true,
            },
            {
              field: 'name',
              filterOptions: {enabled: true, placeholder: ''},
              label: 'Tên tình trạng',
              required:true,
            },
            { field: 'action', label: 'Actions',width: '90px', }
          ],
        },

        {
          value:'catalog_sources',
          text:'Nguồn văn bản',
          fields: [
            {
              field: 'id',
              filterOptions: {enabled: true, placeholder: ''},
              label: 'ID nguồn văn bản',
              required:true,
              autoIncrement: true,
              width: '80px',
              hidden: true,
            },
            {
              field: 'name',
              filterOptions: {enabled: true, placeholder: ''},
              label: 'Tên nguồn văn bản',
              required:true,
            },
            { field: 'action', label: 'Actions',width: '90px', }
          ],
        },

        {
          value:'catalog_careers',
          text:'Ngành nghề',
          fields: [
            {
              field: 'id',
              filterOptions: {enabled: true, placeholder: ''},
              label: 'ID ngành nghề',
              required:true,
              autoIncrement: true,
              width: '80px',
              hidden: true,
            },
            {
              field: 'name',
              filterOptions: {enabled: true, placeholder: ''},
              label: 'Tên ngành nghề',
              required:true,
            },
            { field: 'action', label: 'Actions',width: '90px', }
          ],
        },

        {
          value:'catalog_signers',
          text:'Người ký',
          fields: [
            {
              field: 'id',
              filterOptions: {enabled: true, placeholder: ''},
              label: 'ID người ký',
              required:true,
              autoIncrement: true,
              width: '80px',
              hidden: true,
            },
            {
              field: 'name',
              filterOptions: {enabled: true, placeholder: ''},
              label: 'Tên người ký',
              required:true,
            },
            { field: 'action', label: 'Actions',width: '90px', }
          ],
        },

        {
          value:'catalog_doc_extent',
          text:'Văn bản mở rộng',
          fields: [
            {
              field: 'id',
              filterOptions: {enabled: true, placeholder: ''},
              label: 'ID văn bản mở rộng',
              required:true,
              autoIncrement: true,
              width: '80px',
              hidden: true,
            },
            {
              field: 'name',
              filterOptions: {enabled: true, placeholder: ''},
              label: 'Tên văn bản mở rộng',
              required:true,
            },
            { field: 'action', label: 'Actions',width: '90px', }
          ],
        },

      ],
      fields: [],
      allFields: [],
      serverParams: {
        columnFilters: {},
        sort: [],
        page: 1, 
        pageSize: 50
      },
      required
    }
  },
}
</script>
<style>
.select2-selection--single{
    border: 1px solid #2B938C;
    border-radius: 1.5rem;
}
.b-form-datepicker.form-control-sm .form-control-sm, .b-form-timepicker.form-control-sm .form-control-sm {
    line-height: 2;
}
.select2-selection--single:before {
  content: "";
  position: absolute;
  right: 14px;
  top: 0;
  bottom: 0;
  width: 14px;
  background: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTAiIGhlaWdodD0iNiIgdmlld0JveD0iMCAwIDEwIDYiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxwYXRoIGQ9Ik0xIDFMNSA1TDkgMSIgc3Ryb2tlPSIjMkI5MzhDIiBzdHJva2Utd2lkdGg9IjEuMyIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIiBzdHJva2UtbGluZWpvaW49InJvdW5kIi8+Cjwvc3ZnPgo=") center / contain no-repeat;
}
.select2-selection__arrow{
  background: none;
  display: none !important;
}
.vgt-table td,.vgt-table th{
  padding: 0.25em 0.75em !important;
  vertical-align: middle !important;
  font-size: 1rem;
}
.vgt-table th{
  text-align: center;
}
</style>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>